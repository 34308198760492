<template>
    <div class="mine">
        <div class="boxs">
            <div class="left">
                <div @click="navTo(index)" :class="index == mineIndex ? 'active' : ''" v-for="(item, index) of $t('mine.menuList2')" :key="index">{{ item }}</div>
            </div>
            <div class="right">
                <keep-alive include="orderList">
                <router-view></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>
<script>
// import $bus from '@/util/bus.js'
export default {
    data() {
        return {
            mineIndex: 0
        }
    },
    watch: {
        $route() {
            this.initActive()
        }
    },
    mounted() {
        this.initActive();
        // $bus.$on('updateRouter', this.initActive)//页头也跳转过来
    },
    beforeDestroy() {
        // $bus.$off('updateRouter')
    },
    methods: {
        initActive() {
            this.getPath(2)
        },
        getPath(type, index) {
            var path = {
                0: '/mine/userInfo',
                1: '/mine/shoppingCart',
                2: '/mine/orderList',
                3: '/mine/vipGrade',
                4: '/mine/setingPassword',
            }
            if (type == 1) {
                return path[index]
            } else {
                // Object.keys(path).forEach((key) => {
                //     if (path[key] == this.$route.path) {
                //         this.mineIndex = key
                //     }
                // })
                const pathIndex = Object.keys(path).findIndex(key => path[key] == this.$route.path)
                this.mineIndex = pathIndex > -1 ? pathIndex : null
            }
        },
        navTo(index) {
            if (this.mineIndex == index) {
                return
            }
            this.mineIndex = index;
            this.$router.push({ path: this.getPath(1, index) })
        }
    }
}
</script>
<style lang="scss">
.mine {
    padding: 40px 0px;

    .boxs {
        width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        display: flex;
        align-items: flex-start;

        .left {
            div {
                width: 280px;
                height: 40px;
                padding: 9px 0px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
            }

            .active {
                background: #F8F8F8;
                transition: background .2s ease .2s;
            }
        }
    }
}
</style>